// File#: _1_reveal-effects
// Usage: codyhouse.co/license
(function() {
	var fxElements = document.getElementsByClassName('reveal-fx');
	var intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype);
	if(fxElements.length > 0) {
		// deactivate effect if Reduced Motion is enabled
		if (Util.osHasReducedMotion() || !intersectionObserverSupported) {
			fxRemoveClasses();
			return;
		}
		//on small devices, do not animate elements -> reveal all
		if( fxDisabled(fxElements[0]) ) {
			fxRevealAll();
			return;
		}

		var fxRevealDelta = 120; // amount (in pixel) the element needs to enter the viewport to be revealed - if not custom value (data-reveal-fx-delta)
		
		var viewportHeight = window.innerHeight,
			fxChecking = false,
			fxRevealedItems = [],
			fxElementDelays = fxGetDelays(), //elements animation delay
			fxElementDeltas = fxGetDeltas(); // amount (in px) the element needs enter the viewport to be revealed (default value is fxRevealDelta) 
		
		
		// add event listeners
		window.addEventListener('load', fxReveal);
		window.addEventListener('resize', fxResize);

		// observe reveal elements
		var observer = [];
		initObserver();

		function initObserver() {
			for(var i = 0; i < fxElements.length; i++) {
				observer[i] = new IntersectionObserver(
					function(entries, observer) { 
						if(entries[0].isIntersecting) {
							fxRevealItemObserver(entries[0].target);
							observer.unobserve(entries[0].target);
						}
					}, 
					{rootMargin: "0px 0px -"+fxElementDeltas[i]+"px 0px"}
				);
	
				observer[i].observe(fxElements[i]);
			}
		};

		function fxRevealAll() { // reveal all elements - small devices
			for(var i = 0; i < fxElements.length; i++) {
				Util.addClass(fxElements[i], 'reveal-fx--is-visible');
			}
		};

		function fxResize() { // on resize - check new window height and reveal visible elements
			if(fxChecking) return;
			fxChecking = true;
			(!window.requestAnimationFrame) ? setTimeout(function(){fxReset();}, 250) : window.requestAnimationFrame(fxReset);
		};

		function fxReset() {
			viewportHeight = window.innerHeight;
			fxReveal();
		};

		function fxReveal() { // reveal visible elements
			for(var i = 0; i < fxElements.length; i++) {(function(i){
				if(fxRevealedItems.indexOf(i) != -1 ) return; //element has already been revelead
				if(fxElementIsVisible(fxElements[i], i)) {
					fxRevealItem(i);
					fxRevealedItems.push(i);
				}})(i); 
			}
			fxResetEvents(); 
			fxChecking = false;
		};

		function fxRevealItem(index) {
			if(fxElementDelays[index] && fxElementDelays[index] != 0) {
				// wait before revealing element if a delay was added
				setTimeout(function(){
					Util.addClass(fxElements[index], 'reveal-fx--is-visible');
				}, fxElementDelays[index]);
			} else {
				Util.addClass(fxElements[index], 'reveal-fx--is-visible');
			}
		};

		function fxRevealItemObserver(item) {
			var index = Util.getIndexInArray(fxElements, item);
			if(fxRevealedItems.indexOf(index) != -1 ) return; //element has already been revelead
			fxRevealItem(index);
			fxRevealedItems.push(index);
			fxResetEvents(); 
			fxChecking = false;
		};

		function fxGetDelays() { // get anmation delays
			var delays = [];
			for(var i = 0; i < fxElements.length; i++) {
				delays.push( fxElements[i].getAttribute('data-reveal-fx-delay') ? parseInt(fxElements[i].getAttribute('data-reveal-fx-delay')) : 0);
			}
			return delays;
		};

		function fxGetDeltas() { // get reveal delta
			var deltas = [];
			for(var i = 0; i < fxElements.length; i++) {
				deltas.push( fxElements[i].getAttribute('data-reveal-fx-delta') ? parseInt(fxElements[i].getAttribute('data-reveal-fx-delta')) : fxRevealDelta);
			}
			return deltas;
		};

		function fxDisabled(element) { // check if elements need to be animated - no animation on small devices
			return !(window.getComputedStyle(element, '::before').getPropertyValue('content').replace(/'|"/g, "") == 'reveal-fx');
		};

		function fxElementIsVisible(element, i) { // element is inside viewport
			return (fxGetElementPosition(element) <= viewportHeight - fxElementDeltas[i]);
		};

		function fxGetElementPosition(element) { // get top position of element
			return element.getBoundingClientRect().top;
		};

		function fxResetEvents() { 
			if(fxElements.length > fxRevealedItems.length) return;
			// remove event listeners if all elements have been revealed
			window.removeEventListener('load', fxReveal);
			window.removeEventListener('resize', fxResize);
		};

		function fxRemoveClasses() {
			// Reduced Motion on or Intersection Observer not supported
			while(fxElements[0]) {
				// remove all classes starting with 'reveal-fx--'
				var classes = fxElements[0].getAttribute('class').split(" ").filter(function(c) {
					return c.lastIndexOf('reveal-fx--', 0) !== 0;
				});
				fxElements[0].setAttribute('class', classes.join(" ").trim());
				Util.removeClass(fxElements[0], 'reveal-fx');
			}
		};
	}
}());


// File#: _1_looping_tabs
// Usage: codyhouse.co/license
(function() { 
	var LoopTab = function(opts) {
	  this.options = Util.extend(LoopTab.defaults , opts);
		  this.element = this.options.element;
		  this.tabList = this.element.getElementsByClassName('js-loop-tabs__controls')[0];
		  this.listItems = this.tabList.getElementsByTagName('li');
		  this.triggers = this.tabList.getElementsByTagName('a');
		  this.panelsList = this.element.getElementsByClassName('js-loop-tabs__panels')[0];
	  this.panels = Util.getChildrenByClassName(this.panelsList, 'js-loop-tabs__panel');
	  this.assetsList = this.element.getElementsByClassName('js-loop-tabs__assets')[0];
		  this.assets = this.assetsList.getElementsByTagName('li');
		  this.videos = getVideoElements(this);
	  this.panelShowClass = 'loop-tabs__panel--selected';
		  this.assetShowClass = 'loop-tabs__asset--selected';
		  this.assetExitClass = 'loop-tabs__asset--exit';
	  this.controlActiveClass = 'loop-tabs__control--selected';
	  // autoplay
	  this.autoplayPaused = false;
		  this.loopTabAutoId = false;
		  this.loopFillAutoId = false;
		  this.loopFill = 0;
		  initLoopTab(this);
	  };
	  
	  function getVideoElements(tab) {
		  var videos = [];
		  for(var i = 0; i < tab.assets.length; i++) {
			  var video = tab.assets[i].getElementsByTagName('video');
			  videos[i] = video.length > 0 ? video[0] : false;
		  }
		  return videos;
	  };
	
	function initLoopTab(tab) {
	  //set initial aria attributes
		  tab.tabList.setAttribute('role', 'tablist');
		  for( var i = 0; i < tab.triggers.length; i++) {
			  var bool = Util.hasClass(tab.triggers[i], tab.controlActiveClass),
		  panelId = tab.panels[i].getAttribute('id');
			  tab.listItems[i].setAttribute('role', 'presentation');
			  Util.setAttributes(tab.triggers[i], {'role': 'tab', 'aria-selected': bool, 'aria-controls': panelId, 'id': 'tab-'+panelId});
			  Util.addClass(tab.triggers[i], 'js-loop-tabs__trigger'); 
		Util.setAttributes(tab.panels[i], {'role': 'tabpanel', 'aria-labelledby': 'tab-'+panelId});
		Util.toggleClass(tab.panels[i], tab.panelShowClass, bool);
			  Util.toggleClass(tab.assets[i], tab.assetShowClass, bool);
			  
			  resetVideo(tab, i, bool); // play/pause video if available
  
			  if(!bool) tab.triggers[i].setAttribute('tabindex', '-1'); 
		  }
		  // add autoplay-off class if needed
		  !tab.options.autoplay && Util.addClass(tab.element, 'loop-tabs--autoplay-off');
		  //listen for Tab events
		  initLoopTabEvents(tab);
	};
  
	function initLoopTabEvents(tab) {
		  if(tab.options.autoplay) { 
			  initLoopTabAutoplay(tab); // init autoplay
			  // pause autoplay if user is interacting with the tabs
			  tab.element.addEventListener('focusin', function(event){
				  pauseLoopTabAutoplay(tab);
				  tab.autoplayPaused = true;
			  });
			  tab.element.addEventListener('focusout', function(event){
				  tab.autoplayPaused = false;
				  initLoopTabAutoplay(tab);
			  });
		  }
  
	  //click on a new tab -> select content
		  tab.tabList.addEventListener('click', function(event) {
			  if( event.target.closest('.js-loop-tabs__trigger') ) triggerLoopTab(tab, event.target.closest('.js-loop-tabs__trigger'), event);
		  });
		  
	  //arrow keys to navigate through tabs 
		  tab.tabList.addEventListener('keydown', function(event) {
			  if( !event.target.closest('.js-loop-tabs__trigger') ) return;
			  if( event.keyCode && event.keyCode == 39 || event.key && event.key.toLowerCase() == 'arrowright' ) {
				  pauseLoopTabAutoplay(tab);
				  selectNewLoopTab(tab, 'next', true);
			  } else if( event.keyCode && event.keyCode == 37 || event.key && event.key.toLowerCase() == 'arrowleft' ) {
				  pauseLoopTabAutoplay(tab);
				  selectNewLoopTab(tab, 'prev', true);
			  }
		  });
	};
  
	function initLoopTabAutoplay(tab) {
		  if(!tab.options.autoplay || tab.autoplayPaused) return;
		  tab.loopFill = 0;
		  var selectedTab = tab.tabList.getElementsByClassName(tab.controlActiveClass)[0];
		  // reset css variables
		  for(var i = 0; i < tab.triggers.length; i++) {
			  if(cssVariableSupport) tab.triggers[i].style.setProperty('--loop-tabs-filling', 0);
		  }
		  
		  tab.loopTabAutoId = setTimeout(function(){
		selectNewLoopTab(tab, 'next', false);
		  }, tab.options.autoplayInterval);
		  
		  if(cssVariableSupport) { // tab fill effect
			  tab.loopFillAutoId = setInterval(function(){
				  tab.loopFill = tab.loopFill + 0.005;
				  selectedTab.style.setProperty('--loop-tabs-filling', tab.loopFill);
			  }, tab.options.autoplayInterval/200);
		  }
	};
  
	function pauseLoopTabAutoplay(tab) { // pause autoplay
	  if(tab.loopTabAutoId) {
			  clearTimeout(tab.loopTabAutoId);
			  tab.loopTabAutoId = false;
			  clearInterval(tab.loopFillAutoId);
			  tab.loopFillAutoId = false;
			  // make sure the filling line is scaled up
			  var selectedTab = tab.tabList.getElementsByClassName(tab.controlActiveClass);
			  if(selectedTab.length > 0) selectedTab[0].style.setProperty('--loop-tabs-filling', 1);
		  }
	};
  
	function selectNewLoopTab(tab, direction, bool) {
	  var selectedTab = tab.tabList.getElementsByClassName(tab.controlActiveClass)[0],
			  index = Util.getIndexInArray(tab.triggers, selectedTab);
		  index = (direction == 'next') ? index + 1 : index - 1;
		  //make sure index is in the correct interval 
		  //-> from last element go to first using the right arrow, from first element go to last using the left arrow
		  if(index < 0) index = tab.listItems.length - 1;
		  if(index >= tab.listItems.length) index = 0;	
		  triggerLoopTab(tab, tab.triggers[index]);
		  bool && tab.triggers[index].focus();
	};
  
	function triggerLoopTab(tab, tabTrigger, event) {
		  pauseLoopTabAutoplay(tab);
		  event && event.preventDefault();	
		  var index = Util.getIndexInArray(tab.triggers, tabTrigger);
		  //no need to do anything if tab was already selected
		  if(Util.hasClass(tab.triggers[index], tab.controlActiveClass)) return;
		  
		  for( var i = 0; i < tab.triggers.length; i++) {
			  var bool = (i == index),
				  exit = Util.hasClass(tab.triggers[i], tab.controlActiveClass);
			  Util.toggleClass(tab.triggers[i], tab.controlActiveClass, bool);
		Util.toggleClass(tab.panels[i], tab.panelShowClass, bool);
			  Util.toggleClass(tab.assets[i], tab.assetShowClass, bool);
			  Util.toggleClass(tab.assets[i], tab.assetExitClass, exit);
			  tab.triggers[i].setAttribute('aria-selected', bool);
			  bool ? tab.triggers[i].setAttribute('tabindex', '0') : tab.triggers[i].setAttribute('tabindex', '-1');
  
			  resetVideo(tab, i, bool); // play/pause video if available
  
			  // listen for the end of animation on asset element and remove exit class
			  if(exit) {(function(i){
				  tab.assets[i].addEventListener('transitionend', function cb(event){
					  tab.assets[i].removeEventListener('transitionend', cb);
					  Util.removeClass(tab.assets[i], tab.assetExitClass);
				  });
			  })(i);}
		  }
	  
	  // restart tab autoplay
	  initLoopTabAutoplay(tab);
	  };
  
	  function resetVideo(tab, i, bool) {
		  if(tab.videos[i]) {
			  if(bool) {
				  tab.videos[i].play();
			  } else {
				  tab.videos[i].pause();
				  tab.videos[i].currentTime = 0;
			  } 
		  }
	  };
  
	LoopTab.defaults = {
	  element : '',
	  autoplay : true,
	  autoplayInterval: 5000
	};
  
	//initialize the Tab objects
	  var loopTabs = document.getElementsByClassName('js-loop-tabs');
	  if( loopTabs.length > 0 ) {
		  var reducedMotion = Util.osHasReducedMotion(),
			  cssVariableSupport = ('CSS' in window) && Util.cssSupports('color', 'var(--var)');
		  for( var i = 0; i < loopTabs.length; i++) {
			  (function(i){
		  var autoplay = (loopTabs[i].getAttribute('data-autoplay') && loopTabs[i].getAttribute('data-autoplay') == 'off' || reducedMotion) ? false : true,
		  autoplayInterval = (loopTabs[i].getAttribute('data-autoplay-interval')) ? loopTabs[i].getAttribute('data-autoplay-interval') : 5000;
		  new LoopTab({element: loopTabs[i], autoplay : autoplay, autoplayInterval : autoplayInterval});
		})(i);
		  }
	  }
  }());